$(document).ready(function () {
      let basePath = $('div.onecloud-login-domain').data('base-url');
      let apiName = 'getAccessToken'
      let tokenRefresh;
      let refreshTime;
      let refreshtokenvalue ;
      let accessToken ;
      accessToken = getCookie('token');
      refreshTime = Number(getCookie('exp')) ;
      //Function to call get acces token api
       tokenRefresh = setInterval(refreshToken, 1000 * 60 * refreshTime - 2);
       if(accessToken == null || accessToken == undefined )
       {
        refreshToken();
       }
     function refreshToken() {
         refreshtokenvalue = getCookie('refreshtoken');
        if (refreshtokenvalue != null || refreshtokenvalue != undefined) {
          $.ajax({
            type: "GET",
            beforeSend: function (xhr) {
              xhr.setRequestHeader("Authorization", "Bearer " + refreshtokenvalue);
              xhr.setRequestHeader("appkey", "2fa85n3v6fjqg37e1d0n04tl1h");
            },
            url: basePath +apiName,
            dataType: "json",
            processData: false,
            contentType: false,
            success: function (data) {
              let cookieName = 'token';
              let cookieValue = data.data.accessToken;
              document.cookie = cookieName +';domain=.netgear.com;path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              document.cookie = cookieName + "=" + cookieValue + ";" + '' + ";domain=.netgear.com;path=/";
            },
            error: function (response) {
               let cookieName = 'token';
              document.cookie = cookieName +';domain=.netgear.com;path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            },
          });
        }
        else {
          clearInterval(tokenRefresh)
        }
      }
      //Function to fetch particular cookie name
      function getCookie(cookieName) {
        let cookie = {};
        document.cookie.split(';').forEach(function (el) {
          let [key, value] = el.split('=');
          cookie[key.trim()] = value;
        })
        return cookie[cookieName];
      }
  });
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var tagManager = require('int_googletags/tagManager');

$(document).ready(function () {
    if ('pageContext' in window) {
        tagManager.init(window.pageContext.ns);
    }
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('lyonscg/components/backtotop'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('lyonscg/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('lyonscg/components/carousels'));
    processInclude(require('./components/tooltips'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/liveAgent'));
    processInclude(require('./components/carousels'));
    processInclude(require('./config/slickConfigs'));
    processInclude(require('./components/marketing'));
    processInclude(require('./components/promoModal'));
    processInclude(require('./components/promoPage'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('svg4everybody');
require('slick-carousel');
require('js-cookie');
require('jquery-typeahead');
require('./components/postOnReady');
require('./components/cognito');
require('./lib/jquery.syncHeight.min');

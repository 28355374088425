'use strict';
var slickConfigs = require('../config/slickConfigs');
var imagesloaded = require('imagesloaded');

/**
 * Init globally reusable carousels
 */

/**
 * Update Carousel tiles height
 * @param {Object} $carousel is a jQuery Element Object
 */
function updateTileHeight($carousel) {
    var $productTiles = $carousel.find('.featured-product-tile-3');
    if ($productTiles.length) {
        $productTiles.find('.image-container').syncHeight({ 'updateOnResize': true }); // eslint-disable-line
        $productTiles.find('.pdp-link .js-product-name').syncHeight({ 'updateOnResize': true }); // eslint-disable-line
        $productTiles.find('.bv-ratings-wrapper').syncHeight({ 'updateOnResize': true }); // eslint-disable-line
        $productTiles.find('.edd-message').syncHeight({ 'updateOnResize': true }); // eslint-disable-line
        $productTiles.find('.affirm-promo-wrapper').syncHeight({ 'updateOnResize': true }); // eslint-disable-line
        $productTiles.find('.product-pricing').syncHeight({ 'updateOnResize': true }); // eslint-disable-line
    }
}

/**
 * initialize recommendation Carousels
 * @param {Object} $carousel is a jQuery Element Object
 */
function recommendationCarousels($carousel) {
    $carousel.each(function () {
        var $this = $(this);
        $this.on('init', function () {
            updateTileHeight($(this));
        });
        if ($this.hasClass('product-tile-caro-four')) {
            $this.slick(slickConfigs.productTiles($this));
        } else if ($this.hasClass('cart-recommendation')) {
            $this.slick(slickConfigs.fiveByCarousel($this));
        } else if ($this.hasClass('cart-service-container')) {
            $this.slick(slickConfigs.serviceCarousel($this));
        } else {
            $this.slick(slickConfigs.recommendationproductTiles($this));
        }
    });
}

module.exports = {
    heroCarousels: function () {
        $('.hero-caro').slick(slickConfigs.hero);
    },

    fiveByCarousel: function () {
        $('.five-by-carousel').each(function () {
            var $this = $(this);
            $this.on('init', function () {
                updateTileHeight($(this));
            });
            $this.slick(slickConfigs.fiveByCarousel($this));
        });
        $('.five-by-carousel-orbi').each(function () {
            var $this = $(this);
            $this.slick(slickConfigs.fiveByCarouselOrbi($this));
        });
    },

    autoRotateCarousels: function () { // declaring carousel for CHP
        $('.contentAsset-auto-caro').slick(slickConfigs.autoRotateCarousels);
    },

    specialOfferCarousels: function () {
        $('.specialoffers-carousel').slick(slickConfigs.specialOfferCarousels);
    },

    featureProdCarousels: function () { // declaring the carousel for feature products
        $('.cat-marketing-accord-caro').slick(slickConfigs.featureProdCarouselsMobile);
        $('.cat-marketing-tab-caro').slick(slickConfigs.featureProdCarouselsDesktop);
    },

    flexThreeCarousels: function () { // declaring the carousel for the flexThree templates
        /* Slick needs no get Reinitialized on window Resize after it was destroyed */
        $(window).on('load resize orientationchange', function () {
            $('.tile-container').each(function () {
                var $carousel = $(this);
                if ($(window).outerWidth() > 1024) {
                    if ($($carousel).hasClass('slick-initialized')) {
                        $carousel.slick('unslick');
                    }
                } else if (!$carousel.hasClass('slick-initialized')) {
                    $carousel.slick(slickConfigs.flexThreeCarousels);
                }
            });
        });
    },

    adjustTilesHeight: function () {
        setTimeout(function () {
            $(window).resize();
        }, 3000);
    },

    carouselInit: function () {
        var $productContainer = $('.product-detail');
        var $carousels = $productContainer.find('.product-carousel');
        var $carouselsSmall = $productContainer.find('.product-small-carousel');

        if ($carousels.length) {
            imagesloaded($carousels).on('done', function () {
                $carousels.each(function () {
                    var $carousel = $(this);

                    $carousel.not('.slick-initialized').slick(slickConfigs.pdp);
                });
            });
        }

        if ($carouselsSmall.length) {
            imagesloaded($carouselsSmall).on('done', function () {
                $carouselsSmall.each(function () {
                    var $carousel = $(this);

                    $carousel.not('.slick-initialized').slick(slickConfigs.pdpSmall);
                });
            });
        }

        $(window).on('carouselLoaded:heroThreeProduct carouselLoaded:pageRecommendation', function () {
            var $carousel = $('.page-recommendation:not(.slick-initialized)');
            if ($carousel.length) {
                recommendationCarousels($carousel);
            }
        });

        $(window).on('carouselLoaded:productDetails', function () {
            var $carousel = $('.product-tile-caro-four:not(.slick-initialized)');
            if ($carousel.length) {
                recommendationCarousels($carousel);
            }
        });

        $(window).on('carouselLoaded:serviceCarousel', function () {
            var $carousel = $('.cart-service-container:not(.slick-initialized)');
            if ($carousel.length) {
                recommendationCarousels($carousel);
            }
        });

        $(window).on('carouselLoaded:accessoriesCarousel', function () {
            var $carousel = $('.cart-service-container:not(.slick-initialized)');
            if ($carousel.length) {
                recommendationCarousels($carousel);
            }
        });
        window.carouselHandlersInitialised = true;
    }
};

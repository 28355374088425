'use strict';
/* global pageContext */

var dataLayer = window.dataLayer;

/**
 * @param {Object} productObject The product data
 * @description gets the data for a product click
 */
function productClick(productObject) {
    var obj = {
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: {
                    list: 'SearchResults'
                },
                products: []
            }
        }
    };

    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {string} quantity product quantity
 */
function addToCart(productObject, quantity) {
    var quantityObj = {
        quantity: quantity
    };

    var obj = {
        event: 'addToCart',
        ecommerce: {
            add: {
                products: []
            }
        }
    };

    obj.ecommerce.add.products.push($.extend(productObject, quantityObj));
    obj.visitor = $.extend({}, dataLayer[0].visitor);
    dataLayer.push(obj);
}

// eslint-disable-next-line valid-jsdoc
/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {string} quantity product quantity
 * @param {string} currentList
 * @param {string} currentPosition
 */
function addTileToCart(productObject, quantity, currentList, currentPosition) {
    var productAttrObj = {
        quantity: quantity,
        position: currentPosition
    };

    var obj = {
        event: 'addToCart',
        ecommerce: {
            add: {
                actionField: {
                    list: currentList
                },
                products: []
            }
        }
    };

    obj.ecommerce.add.products.push($.extend(productObject, productAttrObj));
    obj.visitor = $.extend({}, dataLayer[0].visitor);
    dataLayer.push(obj);
}
/**
 *
 * @param {Object} productObject product object
 * @param {string|number} quantity product quantity
 */
function removeFromCart(productObject, quantity) {
    var quantityObj = {
        quantity: quantity
    };

    var obj = {
        event: 'removeFromCart',
        ecommerce: {
            remove: {
                products: []
            }
        }
    };

    obj.ecommerce.remove.products.push($.extend(productObject, quantityObj));
    obj.visitor = $.extend({}, dataLayer[0].visitor);
    dataLayer.push(obj);
}

/**
 * Updates the current step in the checkout flow
 * @param {Integer} step the step number the flow is currently on
 */
function updateCheckoutStep(step) {
    var obj = {
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step: step
                }
            }
        }
    };
    
    if (step === 5) {
        if ($('.payment-details .credit-card-number').length > 0) {
            obj.checkoutPaymentMethod = 'credit card';
        } else {
            obj.checkoutPaymentMethod = $('.paypal-method-name').data('paypal-type');
        }
    }
    dataLayer.push(obj);
}

/**
 * @description Convenience method for creating a click event.
 * @param {string} event event
 * @param {string} eventCategory event categroy
 * @param {string} eventAction event action
 * @param {string} eventLabel event layer
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
    });
}

var previous;

/**
 * @description Convenience method for getting quantity went removing
 * @param {Object} productObj productObj
 * @return {int} quantity value of quantity
 */
function getQuantityValue(productObj) {
    var quantity = 1;
    $('select.quantity').each(function () {
        var datapid = $(this).attr('data-pid');
        if (datapid === productObj.id || datapid === productObj.childID) {
            quantity = $(this).val();
        }
    });
    return quantity;
}
var events = {
    account: function () {},
    cart: function () {
        $('.cart-page .quantity.custom-select').on('focus', function () {
         // Store the current value on focus and on change
            previous = this.value;
        }).change(function () {
            // Do something with the previous value after the change
            var newQty = this.value;
            if (newQty > previous) {
                addToCart($.parseJSON($(this).attr('data-gtmdata')), newQty - previous);
            } else {
                removeFromCart($.parseJSON($(this).attr('data-gtmdata')), previous - newQty);
            }
        });

        $('.cart-page .remove-product').on('click', function () {
            var productObj = $.parseJSON($(this).attr('data-gtmdata'));
            // Send removeFromCart event when confirmation is complete
            $(document).on('click', '.cart-delete-confirmation-btn', function () {
                removeFromCart(productObj, getQuantityValue(productObj));
            });
            $('.cart-page .remove-btn.remove-product').on('click', function () {
                removeFromCart($.parseJSON(productObj), getQuantityValue(productObj));
            });
        });

        $('.cart-page .add-to-cart').on('click', function () {
            addToCart($.parseJSON($(this).attr('data-gtmdata')), 1);
        });

        $('.add-tile-to-cart').on('click', function () {
            var currentPosition = $(this).closest('.product').attr('data-gtm-product-position');
            var currentList = $(this).parents('.page-recommendation').siblings('.callout-msg-recommendations').text().trim();
            addTileToCart($.parseJSON($(this).attr('data-gtmdata')), 1, currentList, currentPosition);
        });
    },
    checkout: function () {

        $(document).on('click', '.btn-edit-multi-ship, .shipping-summary .edit-button', function () {
            updateCheckoutStep(2);
        });

        $(document).on('checkout:updateUrl', function (ev, currentStage) {
            if (currentStage === 'payment') {
                updateCheckoutStep(4);
            } else if (currentStage === 'placeOrder') {
                updateCheckoutStep(5);
            }
        });

        $(document).on('click', '.place-order', function () {
            updateCheckoutStep(6);
        });
    },
    compare: function () {},
    product: function () {
        $('.add-to-cart').on('click', function () {
            addToCart($.parseJSON($(this).attr('data-gtmdata')), $('.quantity-select').val());
        });
    },
    search: function () {
        $('.add-tile-to-cart').on('click', function () {
            var currentPosition = $(this).closest('.grid-tile').attr('data-gtm-product-position');
            var currentList = $(this).parents('.row[class$="-carousel"]').find('.callout-msg-feature-product').text().trim();
            addTileToCart($.parseJSON($(this).attr('data-gtmdata')), 1, currentList, currentPosition);
        });
        $(document).on('click', '.add-to-cart-global', function () {
        	addToCart($.parseJSON($(this).attr('data-gtmdata')), 1);
        });
    },
    storefront: function () {},
    wishlist: function () {},
    // events that should happen on every page
    all: function () {
        $('.pdp-link .link').on('click', function () {
            productClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.nav-link.dropdown-toggle').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', $(this).html());
        });
        $('.navbar-header.brand').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', 'Home Link');
        });
    }
};


/**
 *
 * @description Initialize the tag manager functionality
 * @param {string} nameSpace The current name space
 */
exports.init = function (nameSpace) {
    var ns = nameSpace || pageContext.ns;
    if (ns && events[ns]) {
        events[ns]();
    }
    events.all();
};
